<template>
  <div>
    <van-list v-for="item in list" :key="item + 'DocI'">
      <van-cell center style="background-color:#57c4b7; margin-top:5px;">
        <template slot="icon">
          <van-image width="6em" height="6em" fit="contain" :src="item.logo" alt="证件照" />
          <!-- <img width="60px" height="84px" fit="contain" id="DocImg" alt="证件照" /> -->
        </template>
        <template slot="title">
          <span style="color:white; font-size:20px; margin-left:25px;">{{item.doc}}</span>
        </template>
      </van-cell>
      <van-cell>
        <template slot="title">
          <span style="color:#57c4b7;">医生职称：</span>
          {{item.lev}}
        </template>
      </van-cell>
      <van-cell>
        <template slot="title">
          <span style="color:#57c4b7;">所在科室：</span>
          {{item.dep}}
        </template>
      </van-cell>
      <van-cell>
        <template slot="title">
          <span style="color:#57c4b7;">医生信息：</span>
        </template>
        <template slot="label">
          <p v-for="Ditem in val" :key="Ditem + 'DocI'" style="text-indent:2em;">{{Ditem}}</p>
        </template>
      </van-cell>
    </van-list>
    <div style="font-size: 12px; color: #D3D3D3; margin: 1% 40%; width: auto; height: 40px;"><span>&copy;拓实银医</span></div>
  </div>
</template>

<script>
import ajax from "../../lib/ajax";
import store from "../../store";
import { Toast } from "vant";
// import AppConsts from "../../lib/appconst";
export default {
  data() {
    return {
      list: [],
      val: [],
      // logos: require('@/assets/FGlogo.png'),
      // logos: require('@/assets/JClogo.png'),
      // logos: require('@/assets/Doc.png'),
      logos: require("@/assets/Doc.png")
    };
  },
  created() {
    this.onload();
  },
  methods: {
    async onload() {
      let docName = store.getters["store/dorname"];
      await ajax
        .get("/Api/QueryService/OutputDoctorInfo?input=" + docName)
        .then(res => {
          this.list = res.data.result;
          // console.log(this.list);
          for (let i = 0; i < this.list.length; i++) {
            let name = this.list[i].docName.split(".");
            this.val = this.list[i].docContent.split("&");
            console.log(this.val);
            this.list[i].dep = name[0];
            this.list[i].doc = name[1];
            this.list[i].lev = name[2];
            let http = this.list[i].key;
            this.logos = http === null || http === "" ? this.logos : "https://api.fgxrmyy.cn/api/Attachment/"+http;
            this.list[i].logo = this.logos;
          }
          console.log(this.list);
        })
        .catch(err => {
          Toast("错误：" + err.message);
        });
      // let Url = AppConsts.remoteServiceBaseUrl;
      // var url = Url + "api/Attachment/" + this.list[0].key;
      // var xhr = new XMLHttpRequest();
      // xhr.open("get", url, true);
      // xhr.responseType = "blob"; //也可以使用POST方式，根据接口
      // xhr.setRequestHeader(
      //   "Authorization",
      //   " Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6IjEiLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYWRtaW4iLCJBc3BOZXQuSWRlbnRpdHkuU2VjdXJpdHlTdGFtcCI6IjI2ZjkxOWQ2LWVlNDktODM2Zi1iODhkLTM5ZjU5NmJhMDNjOSIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6IkFkbWluIiwic3ViIjoiMSIsImp0aSI6IjI0YjdmZmY2LTlhMjAtNDQxMC05M2NjLTkzZjE5Y2ZmNTMxNyIsImlhdCI6MTU5MTkyMzY1NiwibmJmIjoxNTkxOTIzNjU2LCJleHAiOjE1OTIwMTAwNTYsImlzcyI6IlpoZW5ZdWFuQXBpIiwiYXVkIjoiWmhlbll1YW5BcGkifQ.I0eLnjRJFMtB6Lc9FN6EWoAevNDvJ-6Q6WzW3lx-v4A"
      // );
      // xhr.onload = function(res) {
      //   if (this.status === 200) {
      //     console.log(res);
      //     var type = xhr.getResponseHeader("Content-Type");
      //     var blob = new Blob([this.response], { type: type });
      //     if (typeof window.navigator.msSaveBlob !== "undefined") {
      //       window.navigator.msSaveBlob(blob);
      //     } else {
      //       var URL = window.URL || window.webkitURL;
      //       var objectUrl = URL.createObjectURL(blob);
      //       var img = document.getElementById("DocImg");
      //       img.setAttribute("src", objectUrl);
      //     }
      //   } else {
      //     alert("获取医生图片失败!");
      //   }
      // };
      // xhr.send();
    }
  }
};
</script>

<style>
</style>
